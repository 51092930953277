import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';

//Alta Components
import { LoginuserComponent } from './components/authorization/loginuser/loginuser.component';

import { AuthGuard } from './shared/provider/auth-guard';
import { ForgotPasswordComponent } from './components/authorization/forgotpassword/forgotpassword.component';
import { ValidateAccountComponent } from './components/authorization/validateaccount/validateaccount.component';
import { ForgotUsernameComponent } from './components/authorization/forgotusername/forgotusername.component';
import { ResetPasswordComponent } from './components/authorization/resetpassword/resetpassword.component';
import { CorporateSelectorComponent } from './components/corporateselector/corporateselector.component';
import { DefaultpageComponent } from './components/defaultpage/defaultpage.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginuserComponent,
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
  },
  {
    path: 'forgotusername',
    component: ForgotUsernameComponent,
  },
  {
    path: 'resetpassword/:eml/:stc/:userId',
    component: ResetPasswordComponent,
  },
  {
    path: 'validateaccount/:eml/:stc',
    component: ValidateAccountComponent,
  },
  {
    path: 'selectcorporate',
    component: CorporateSelectorComponent,
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500',
    },
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: '',
    },
    children: [
      {
        path: 'default',
        component: DefaultpageComponent,
      },
      {
        canActivate: [AuthGuard],
        path: 'home',
        loadChildren: () =>
          import('./components/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'myprojects',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/myProjects/my-projects.module').then(
            (m) => m.MyProjectsModule
          ),
      },
      {
        path: 'general',
        canActivate: [AuthGuard],
        loadChildren: () => 
          import('./components/inputsupport/inputsupport.module').then(
            (m) => m.InputSupportModule
          ),
      },
      {
        path: 'general',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/benefitprogram/benefitprogram.module').then(
            (m) => m.BenefitProgramModule
          ),
      },
      {
        path: 'general',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './components/benefitprogramtemplate/benefitprogramtemplate.module'
          ).then((m) => m.BenefitProgramTemplateModule),
      },
      {
        path: 'general',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/country/country.module').then(
            (m) => m.CountryModule
          ),
      },
      {
        path: 'general',
        loadChildren: () => import('./components/resource/resource.module').then(a => a.ResourceModule)
      },     
      {
        path: 'client/account-config',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/corporate/corporate.module').then(
            (m) => m.CorporateModule
          ),
      },
      {
        path: 'hub/accounts',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/corporate/corporate.module').then(
            (m) => m.CorporateModule
          ),
      },
      {
        path: 'geography',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/geography/geography.module').then(
            (m) => m.GeographyModule
          ),
      },
      {
        path: 'mybenefit',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/mybenefit/mybenefit.module').then(
            (m) => m.MyBenefitModule
          ),
      },
      {
        path: 'report',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/report/report.module').then(
            (m) => m.ReportModule
          ),
      },
      {
        path: 'my-data/data-import',
        canActivate: [AuthGuard],
        loadChildren: () => import('./components/data-import/data-import.module').then(a => a.DataImportModule)
      },
      {
        path: 'manualinput',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/manualinput/manualinput.module').then(
            (m) => m.ManualInputModule
          ),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'mybenefit',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/mybenefit/mybenefit.module').then(
            (m) => m.MyBenefitModule
          ),
      },
      {
        path: 'client/dataMaintenance/vendors',
        loadChildren: () => 
          import('./components/vendor/vendor.module').then(
            (m) => m.VendorModule
          ),
      },
      {
        path: 'client/dataMaintenance/currencyexchange',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/currencyexchange/currencyexchange.module').then(
            (m) => m.CurrencyExchangeModule
          ),
      },
      {
        path: 'client/dataMaintenance/currencyexchange',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/currencyexchange/currencyexchange.module').then(
            (m) => m.CurrencyExchangeModule
          ),
      },
      {
        path: 'client/generalInflation',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/generalMaintenance/generalInflation/generalInflation.module').then(
            (m) => m.GeneralInflationModule
          ),
      },
      {
        path: 'hub/generalInflation',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/generalMaintenance/generalInflation/generalInflation.module').then(
            (m) => m.GeneralInflationModule
          ),
      },
      {
        path: 'client/salaryIncrease',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/generalMaintenance/SalaryIncrease/salaryIncrease.module').then(
            (m) => m.SalaryIncreaseModule
          ),
      },
      {
        path: 'hub/salaryIncrease',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/generalMaintenance/SalaryIncrease/salaryIncrease.module').then(
            (m) => m.SalaryIncreaseModule
          ),
      },
      {
        path: 'client/medicalTrend',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/generalMaintenance/medicalTrend/medicalTrend.module').then(
            (m) => m.MedicalTrendModule
          ),
      },
      {
        path: 'hub/generalMaintenance/medicalTrend',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/generalMaintenance/medicalTrend/medicalTrend.module').then(
            (m) => m.MedicalTrendModule
          ),
      },
      {
        path: 'security/users',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'security/roles',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/role/role.module').then((m) => m.RoleModule),
      },
      {
        path: 'client/users',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'contacts',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './components/contact-layout-filter/contact-layout-filter.module'
          ).then((m) => m.ContactLayoutFilterModule),
      },
      {
        path: 'hub',
        loadChildren: () =>
          import(
            './components/login-content-editor/login-content-editor.module'
          ).then((m) => m.LoginContentEditorModule),
      },
      {
        path: 'hub',
        loadChildren: () =>
          import(
            './components/report/reportcontenteditor/reportcontenteditor.module'
          ).then((m) => m.ReportContentEditorModule),
      },
      {
        path: 'hub/reports',
        loadChildren: () =>
          import(
            './components/report/report.module'
          ).then((m) => m.ReportModule),
      },
      {
        path: 'client',
        loadChildren: () =>
          import(
            './components/report/reportcontenteditor/reportcontenteditor.module'
          ).then((m) => m.ReportContentEditorModule),
      },
      {
        path: 'hub/vendors',
        loadChildren: () =>
          import(
            './components/vendor/vendor.module'
          ).then((m) => m.VendorModule),
      },
      {
        path: 'mydocuments',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/mydocuments/mydocuments.module').then(
            (m) => m.MyDocumentsModule
          ),
      },
      {
        path: 'analytics',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/analytics/analytics.module').then(
            (m) => m.AnalyticsModule
          ),
      },
      {
        path: 'help',
        canActivate: [AuthGuard],
        loadChildren: () => import('./components/help/help.module').then(a => a.HelpModule)
      }
    ],
  },
  { path: '**', component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
