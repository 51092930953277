import { JsonpClientBackend } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Currency } from "../model/currency.model";

@Injectable({
  providedIn: "root",
})
export class LocalstorageService { 

  constructor() {}

  getUserFromStorage() {
    return this.getLocalStorageItem("currentUser");
  }

  getTokenFromStorage() {
    return this.getLocalStorageItem("access_token");
  }

  getLocalStorageItem(key: string) {
    return localStorage.getItem(key);
  }

  getCurrentCorporateId(): number {
    throw new Error('Method not implemented.');
  }

  getUserProfile(): any {
    
    let profile;
    const jsonUser = this.getLocalStorageItem("currentUser");
    
    if(jsonUser){
      profile = JSON.parse(jsonUser).Profile;  
    }

    return profile;
  }

  setLocalStorageItem(key: string, value: Object) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  getPreferredCurrency(): any {    

    let profile = this.getUserProfile();    
    let preferredCurrency: any = {};

    if(profile.PreferredCurrency){

      preferredCurrency['CurrencyId'] = profile.PreferredCurrency.CurrencyId;
      preferredCurrency['Currency'] = profile.PreferredCurrency.Currency;      
      preferredCurrency['Code'] = profile.PreferredCurrency.Code;
    }else{
      
      let corporate = (JSON.parse(profile.Corporates) || []).find(a => a.CorporateId == profile.CorporateId);
      
      preferredCurrency['CurrencyId'] = corporate.HeadquarterCurrencyId;
      preferredCurrency['Currency'] = corporate.HeadquarterCurrency;      
      preferredCurrency['Code'] = corporate.HeadquarterCurrencyCode;
    }

    return preferredCurrency;
  }

  getHeadquarterCurrency(): Currency {
    
    let headquarterCurrency: Currency;
    let profile = this.getUserProfile();
    
    if(profile.Corporates) {
      let currentCorporate = JSON.parse(profile.Corporates).find(a => a.CorporateId = profile.CorporateId);
  
      if(currentCorporate != null)
        headquarterCurrency = new Currency(currentCorporate.HeadquarterCurrencyId, currentCorporate.HeadquarterCurrency, currentCorporate.HeadquarterCurrencyCode)          
        
    }

    return headquarterCurrency;
  }

  getProfileCurrency(): Currency {
    let preferredCurrency: Currency;
    let profile = this.getUserProfile();    

    if(profile.PreferredCurrency)
      preferredCurrency = new Currency( profile.PreferredCurrency.CurrencyId, profile.PreferredCurrency.Currency, profile.PreferredCurrency.Code);   
      
    return preferredCurrency;
  }
}
