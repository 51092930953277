import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Meta } from "@angular/platform-browser";

@Component({
  // tslint:disable-next-line
  selector: "body",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private MetatagService: Meta) {}

  ngOnInit() {
    this.MetatagService.addTags([
      { name: "keywords", content: "HUB Global Benefits Online, Insurace, Data Analityc" },
      { name: "robots", content: "index, follow" },
      { name: "author", content: "HUB Global Benefits" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { charset: "UTF-8" },
    ]);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
