import { Component, OnInit, TemplateRef} from '@angular/core';
import { LoginContentEditorService } from '../../login-content-editor/login-content-editor.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { WrapperArticle, WrapperSection,  Article } from '../../../shared/model/Articles.model';
import { SortPipe } from '../../../shared/pipes/sort.pipe';
import { BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-cards-articles',
  templateUrl: './cards-articles.component.html',
  styleUrls: ['./cards-articles.component.css']
})
export class CardsArticlesComponent implements OnInit {
  translateData: {};
  translateApp: {};
  sectionList: any[];
  articles: any[];
  SectionId: number = 0;
  articleData: any ;
  dataArticlesSorted: any;
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  articleInfo: {
    Content: '',
    Header: '',
    PublicationDate: '',
    Author: '',
    Email: '',
    LinkedUrl: '',
  };
  allArticles: [];
  allArticlesList:  Article [];
  articlesFiles: any [] = [];
  sectionArray: any;
  showSeeMore: boolean = false;
  noFile: boolean = false;
  showLinkedBtn: boolean = false;
  constructor(
    private loginContentEditorService: LoginContentEditorService,
    private toastr: ToastrService,
    private sortPipe: SortPipe,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.getArticles();
  }


getArticles() {
  this.loginContentEditorService.getArticleList(1, 100, 'asc', '').subscribe(
    (result) => {
      this.articles = <any>result['Data'].Rows;
      
      if(this.articles.length == 0) {
        return;
      }   
      const res = this.articles.map(x => x.SectionId).filter((value, index, self) => self.indexOf(value) === index);
      const wrapperSectionList = [];
      res.forEach(r => {
        const _filter =  this.articles.filter( x => x.SectionId === r);
       if (_filter.length > 0 ) {
         const itenFilter = _filter[0];
        const articleList = [];
        _filter
          .sort((l, rr) => l.Order > rr.Order ? 1 : 0)
         .forEach(i => {
          articleList.push(Object.assign(new WrapperArticle(), {
            ArticleId: i.ArticleId,
            Name: i.Name,
            Order: i.Order,
            Header: i.Header,
            PublicationDate: i.PublicationDate,
            Author: i.Author,
            LinkedUrl: i.LinkedUrl,
            Email: i.Email,
            Status: i.Status,
            Content: i.Content
        }));
        
        });
         wrapperSectionList.push(Object.assign(new WrapperSection(), {
          SectionId: r,
          Section: itenFilter.Section,
          Articles: articleList
         }));
       }
      });
      this.articleData = wrapperSectionList;
      const dataArticlesSorted = this.sortPipe.transform(this.articleData, 'asc', 'SectionId');
      this.articleData = dataArticlesSorted;
    },
    (error: HttpErrorResponse) => {
     if (JSON.stringify(error['Success']) !== 'false') {
       this.toastr.error(error.error.Messages[0].Message, 'Error');
     } else {
       this.toastr.error(error.error.message, 'Error');
     }
   }
  );
}

showArticleModal(articleModal: TemplateRef<any>, articleId: number = 0) {
  this.modalRef = this.modalService.show(articleModal, {class: 'modal-lg dialog-scrollable'});
  this.loginContentEditorService.getArticleFilesById(articleId).subscribe(
    (result) => {
      this.articlesFiles = <any>result['Data'][0];
      if (isNullOrUndefined(this.articlesFiles)){
        this.noFile = true;
      } else {
        this.noFile = false;
      }
    },
    (error: HttpErrorResponse) => {
     if (JSON.stringify(error['Success']) !== 'false') {
       this.toastr.error(error.error.Messages[0].Message, 'Error');
     } else {
       this.toastr.error(error.error.message, 'Error');
     }
   }
  );
  this.articleData.map(c => {
    c.Articles.map(d => {
      if (d.ArticleId === articleId) {
        this.articleInfo = d;
        if (this.articleInfo.LinkedUrl == '' || isNullOrUndefined(this.articleInfo.LinkedUrl)){
          this.showLinkedBtn = false;
        }
         else {
          this.showLinkedBtn = true;
        }
      }
    });
  });
}

seeMoreArticles(articleListModal: TemplateRef<any>, sectionId: number = 0) {
  this.modalRef2 = this.modalService.show(articleListModal);
  this.allArticlesList = [];
  this.allArticlesList = this.articles.filter(d => d.SectionId === sectionId);
  if (this.allArticlesList.length > 3) {
    this.showSeeMore = true;
  }
}


}
