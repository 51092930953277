export class Article {
  ArticleId: number;
  SectionId: number;
  Section: string;
  Name: string;
  Header: string;
  PublicationDate: string;
  Author: string;
  LinkedUrl: string;
  Email: string;
  Status: string;
  Order: number;
  Active: boolean;
  ModifiedBy: string;
  ModifiedOn: string;
  Content: string;
}

export class ArticleFiles {
  ArticleFilesId: number;
  ArticleId: number;
  Name: string;
  FileName: string;
  Url: string;
  Active: boolean;
}

export class ArticleModel {
  TotalCount: number;
  Rows: Article;
}

export class Articles {
  Success: boolean;
  Message: string;
  ErrorCode: number;
  Messages: string;
  Data: ArticleModel;
}

export class WrapperSection {
  SectionId: number;
  Section: string;
  Articles: Array<WrapperArticle>;
}


export class WrapperArticle {
  ArticleId: number;
  Name: string;
  Order: number;
  Header: string;
  PublicationDate: string;
  Author: string;
  LinkedUrl: string;
  Email: string;
  Status: string;
  Content: string;
}