import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  containerHeight = new Subject<string>();

  containerHeight$ = this.containerHeight.asObservable();

  constructor() { }

  handleContainerHeight(height) {
    this.containerHeight.next(height);
  }
}
