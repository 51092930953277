export enum ScreenActions {
    ViewMyGeographiesMenu= 1,
    ViewMyGeographiesSubMenu= 2,
    ViewScreenHighlightsInGeography= 3,
    ViewHighligthsInGeography= 4,
    ViewScreenCompanyInGeography= 5,
    ViewCompaniesListInGeography= 6,
    ViewCompanyDetailInGeography= 7,
    ViewBusinessEntityInGeography= 8,
    ViewCorporateEntityListInGeography= 9,
    ViewScreenBenefitPlansInGeography= 10,
    ViewBenefitPlanListInGeography= 11,
    ViewPlanDetailInGeography= 12,
    ViewBenefitProvisionDetailInGeography= 13,
    ViewScreenContactsInGeography= 14,
    ViewContactListInGeography= 15,
    ViewCompanyRepresentingListInGeography= 16,
    ViewScreenVendorsInGeography= 17,
    ViewVendorListInGeography= 18,
    ViewVendorContactListInGeography= 19,
    ViewScreenFinancialsInGeography= 20,
    ViewFinancialOverviewInGeography= 21,
    ViewMyBenefitsMenu= 22,
    ViewRetirementSubMenu= 23,
    ViewScreenHighlightsInRetirement= 24,
    ViewHighlightsInRetirement= 25,
    ViewScreenViewPlansInRetirement= 26,
    ViewPlanDetailInRetirement= 27,
    ViewBenefitProvisionDetailInRetirement= 28,
    ViewScreenVendorInRetirement= 29,
    ViewVendorContactListInRetirement= 30,
    ViewScreenFinancialsInRetirement= 31,
    ViewFinancialOverviewInRetirement= 32,
    ViewHealthcareSubMenu= 33,
    ViewScreenHighlightsInHealthcare= 34,
    ViewHighlightsInHealthcare= 35,
    ViewScreenViewPlansInHealthcare= 36,
    ViewPlanDetailInHealthcare= 37,
    ViewBenefitProvisionDetailInHealthcare= 38,
    ViewScreenVendorInHealthcare= 39,
    ViewVendorContactListInHealthcare= 40,
    ViewScreenFinancialsInHealthcare= 41,
    ViewFinancialOverviewInHealthcare= 42,
    ViewDeathSubMenu= 43,
    ViewScreenHighlightsInDeath= 44,
    ViewHighlightsInDeath= 45,
    ViewScreenViewPlansInDeath= 46,
    ViewPlanDetailInDeath= 47,
    ViewBenefitProvisionDetailInDeath= 48,
    ViewScreenVendorInDeath= 49,
    ViewVendorContactListInDeath= 50,
    ViewScreenFinancialsInDeath= 51,
    ViewFinancialOverviewInDeath= 52,
    ViewDisabilitySubMenu= 53,
    ViewScreenHighlightsInDisability= 54,
    ViewHighlightsInDisability= 55,
    ViewScreenViewPlansInDisability= 56,
    ViewPlanDetailInDisability= 57,
    ViewBenefitProvisionDetailInDisability= 58,
    ViewScreenVendorInDisability= 59,
    ViewVendorContactListInDisability= 60,
    ViewScreenFinancialsInDisability= 61,
    ViewFinancialOverviewInDisability= 62,
    ViewAccidentSubMenu= 63,
    ViewScreenHighlightsInAccident= 64,
    ViewHighlightsInAccident= 65,
    ViewScreenViewPlansInAccident= 66,
    ViewPlanDetailInAccident= 67,
    ViewBenefitProvisionDetailInAccident= 68,
    ViewScreenVendorInAccident= 69,
    ViewVendorContactListInAccident= 70,
    ViewScreenFinancialsInAccident= 71,
    ViewFinancialOverviewInAccident= 72,
    ViewLeavesSubMenu= 73,
    ViewScreenHighlightsInLeaves= 74,
    ViewHighlightsInLeaves= 75,
    ViewScreenViewPlansInLeaves= 76,
    ViewPlanDetailInLeaves= 77,
    ViewBenefitProvisionDetailInLeaves= 78,
    ViewScreenVendorInLeaves= 79,
    ViewVendorContactListInLeaves= 80,
    ViewScreenFinancialsInLeaves= 81,
    ViewFinancialOverviewInLeaves= 82,
    ViewCompanyCarsSubMenu= 83,
    ViewScreenHighlightsInCompanyCars= 84,
    ViewHighlightsInCompanyCars= 85,
    ViewScreenViewPlansInCompanyCars= 86,
    ViewPlanDetailInCompanyCars= 87,
    ViewBenefitProvisionDetailInCompanyCars= 88,
    ViewScreenVendorInCompanyCars= 89,
    ViewVendorContactListInCompanyCars= 90,
    ViewScreenFinancialsInCompanyCars= 91,
    ViewFinancialOverviewInCompanyCars= 92,
    ViewOtherBenefitsSubMenu= 93,
    ViewScreenHighlightsInOtherBenefits= 94,
    ViewHighlightsInOtherBenefits= 95,
    ViewScreenViewPlansInOtherBenefits= 96,
    ViewPlanDetailInOtherBenefits= 97,
    ViewBenefitProvisionDetailInOtherBenefits= 98,
    ViewScreenVendorInOtherBenefits= 99,
    ViewVendorContactListInOtherBenefits= 100,
    ViewScreenFinancialsInOtherBenefits= 101,
    ViewFinancialOverviewInOtherBenefits= 102,
    ViewReportsMenu= 103,
    ViewBenefitInventoryReportSubMenu= 104,
    ViewBenefitInventoryReports= 105,
    ViewBenefitInventoryAllReports= 106,
    ViewAnalyticsReportSubMenu= 107,
    ViewAnalyticsReports= 108,
    ViewAnalyticsAllReports= 109,
    ViewProjectManagementReportSubMenu= 110,
    ViewProjectManagementReports= 111,
    ViewProjectManagementAllReports= 112,
    ViewResearchReportSubMenu= 113,
    ViewResearchReports= 114,
    ViewResearchAllReports= 115,
    ViewCommunicationReportSubMenu= 116,
    ViewCommunicationReports= 117,
    ViewCommunicationAllReports= 118,
    ViewApexAnalyticsMenu= 119,
    ViewBuildInSubMenu= 120,
    ViewScreenBuildIn= 121,
    ViewBuildIn= 122,
    ViewAdHocSubMenu= 123,
    ViewScreenAdHoc= 124,
    ViewAdHoc= 125,
    ViewMyCalendarMenu= 126,
    ViewMyCalendarSubMenu= 127,
    ViewScreenMyCalendar= 128,
    ViewMyCalendar= 129,
    ViewMyDocumentsMenu= 130,
    ViewMyDocumentsSubMenu= 131,
    ViewScreenMyDocuments= 132,
    ViewMyDocuments= 133,
    ViewMyProjectsMenu= 146,
    ViewInsuranceRenewalsSubMenu= 147,
    ViewScreenInsuranceRenewals= 148,
    ViewInsuranceRenewals= 149,
    ViewPensionGovernanceSubMenu= 150,
    ViewScreenPensionGovernance= 151,
    ViewPensionGovernance= 152,
    ViewMAProjectsSubMenu= 153,
    ViewMAProjectsGovernance= 155,
    ViewBenefitInitiativesSubMenu= 156,
    ViewScreenBenefitInitiatives= 157,
    ViewBenefitInitiatives= 158,
    ViewHQProjectsSubMenu= 159,
    ViewScreenHQProjects= 160,
    ViewHQProjects= 161,
    ViewMyDataMenu= 162,
    ViewDataExportSubMenu= 163,
    ViewScreenDataExport= 164,
    ViewDataExport= 165,
    ViewManualInputSubMenu= 166,
    ViewCompaniesListInMyData= 167,
    ViewCompanyListInMyData= 168,
    AddEntity= 169,
    EditLocalCompany= 170,
    EditAssociatedBusiness= 171,
    EditAssociatedCorporateEntities= 172,
    EditLegalEntity= 173,
    DeleteLegalEntity= 174,
    ViewScreenBenefitPlans= 175,
    ViewBenefitPlanListInMyData= 176,
    AddNewPlan= 177,
    RenewBenefitPlan= 178,
    ChangePlanStatus= 179,
    EditPlanCoverageElements= 180,
    EditPlanCoreInformation= 181,
    EditBenefitDetails= 182,
    ViewDataImportSubMenu= 183,
    ViewScreenDataImport= 184,
    ViewScreenData= 185,
    ViewMyContactsMenu= 186,
    ViewMyContactsSubMenu= 187,
    ViewScreenMyContacts= 188,
    ViewContactListInMyContacts= 189,
    AddContact= 190,
    ViewMyAccountMenu= 191,
    ViewMyClientsSubMenu= 192,
    ViewScreenCorporates= 193,
    ViewCorporateList= 194,
    AddNewCorporate= 195,
    ViewCorporateDetail= 196,
    EditCorporateDetail= 197,
    DeleteCorporate= 198,
    ViewCurrencyExchangeSubMenu= 199,
    ViewScreenCurrencyExchange= 200,
    ViewCurrencyExchange= 201,
    AddCurrencyExchange= 202,
    EditCurrencyExchange= 203,
    DeleteCurrencyExchange= 204,
    ExportCurrencyExchange= 205,
    ImportCurrencyExchange= 206,
    ViewSecurityMenu= 207,
    ViewUsersSubMenu= 208,
    ViewScreenUser= 209,
    ViewUsersList= 210,
    AddNewUser= 211,
    EditUser= 212,
    ResetPassword= 213,
    ChangeStatusUser= 214,
    AssignPermissions= 223,
    ViewSupportListSubMenu= 225,
    ViewScreenSupportList= 226,
    ViewBenefitTypeSubMenu= 231,
    ViewScreenBenefitType= 232,
    ViewBenefitProgramTemplateSubMenu= 237,
    ViewScreenBenefitProgramTemplate= 238,
    ViewCountrySubMenu= 243,
    ViewScreenCountry= 244,
    ViewLogingContentEditorSubMenu= 247,
    ViewScreenLogingContentEditor= 248,
    ViewLogingContentEditor= 249,
    AddNewLoginContentEditor= 250,
    CloneRoles=251,
    ViewReportContentEditorSubMenu=252,
    ViewScreenReportContentEditor=253,
    ViewReportContentEditor=254,
    ViewHomeMenu=255,
    ViewHomeSubMenu=256,
    ViewHomeScreen=257,
    ViewHome=258,
    ViewClientManagementMenu=259,
    ViewApexKeeperMenu=260,
    ViewAccountConfig=261,
    ViewClientSPD=263,
    ViewClientMarketPractice=264,
    ViewClientVendors=265,
    ViewClientForeignExchange=266,
    ViewInformationCenterSubMenu=267,
    ViewApexAccountsSubMenu=268,
    ViewApexSecuritySubMenu=269,
    ViewApexCountryReportsSubMenu=270,
    ViewApexNewAndInsightsSubMenu=271,
    ViewApexLoginPageContent=272,
    ViewApexDataMaintenanceSubMenu=273,
    ViewApexUser=274,
    ViewApexRoles=275,
    ViewApexCountryHealthSummaries=276,
    ViewApexCountryEmployeeBenefitGuide=378,
    ViewApexCountryBenefitSummaries=277,
    ViewApexStatutoryRequirements=278,
    ViewApexMarketPractice=279,
    ViewApexLegistalativeDevelopments=280,
    ViewApexVendors=281,
    ViewApexForeignExchange=282,
    ViewApexUsersList=283,
    AddNewApexUsers=284,
    EditApexUsers=285,
    ResetPasswordApexUsers=286,
    ChangeStatusApexUsers=287,
    ViewApexRoleList=288,
    AddNewApexRole=289,
    EditApexRole=290,
    ChangeStatusApexRole=291,
    CloneApexRoles=292,
    ViewAccountsCorporateList= 293,
    AddNewAccountCorporate= 294,
    ViewAccountCorporateDetail= 295,
    EditAccountCorporateDetail= 296,
    DeleteAccountCorporate= 297,
    ViewClientUsersList= 298,
    AddNewClientUsers= 299,
    EditClientUsers= 300,
    ResetPasswordClientUsers= 301,
    ChangeStatusClientUsers=  302,
    ViewForeignExchangeList= 303,
    AddForeignExchange= 304,
    EditForeignExchange= 305,
    DeleteForeignExchange= 306,
    ExportForeignExchange= 307,
    ImportForeignExchange= 308,
    ViewClientForeignExchangeList= 309,
    AddClientForeignExchange= 310,
    EditClientForeignExchange= 311,
    DeleteClientForeignExchange= 312,
    ExportClientForeignExchange= 313,
    ImportClientForeignExchange= 314,
    ViewSystemMenu= 315,
    ViewSupportList= 316,
    ViewBenefitTypeList= 317,
    ViewBenefitProgramTemplateList= 318,
    ViewCountriesList = 319,
    AddSupportList=320,
    EditSupportList=321,
    DeleteSupportList=322,
    AddBenefitType=323,
    EditBenefitType=324,
    DeleteBenefitType=325,
    AddBenefitProgramTemplate=326,
    EditBenefitProgramTemplate=327,
    DeleteBenefitProgramTemplate=328,
    UpdateCountries=329,
    ViewGlobalOrganizationalLayout=330,
    ViewEmployerSponsoredBenefitPlanPrevalence=331,
    ViewBenefitPlanMembershipSummary=332,
    ViewSupplementaryBenefitPlanAnnualSpend=333,
    ViewRetirementProgramsAroundTheWorld=334,
    ViewGlobalOverviewOfVendors=335,
    ViewGlobalVendorCompensation=336,
    ViewVendorContactSummary=337,
    ViewSummaryPlanDescription=338,
    ViewSideBySidePlanDesign=339,
    ViewGlobalBenefitInventorySummary=340,
    ViewBenefitPlanMasterReport=341,
    ViewCompetitiveBenchmarking=343,
    ViewSupplementaryAnnualBenefitPlanSpendReportPercentageOfPayroll=344,
    ViewMedicalUnderwritingPerformance=345,
    ViewMultinationalPoolingStatus=346,
    ViewGlobalPensionDisclosure=347,
    ViewInsuredBenefitRenewalSchedule=348,
    ViewCountryBenefitSummaries=349,
    ViewCountryHealthSummaries=350,
    ViewCountryBenefitSummariesReport=377,
    ViewCountryRetirementBenefitSummaries=351,
    ViewCountryStatutoryRequirementsReport=352,
    ViewCountryBenefitMarketPractices=353,
    ViewCountryLegislativeDevelopmentsBulletins=354,
    ViewArticles=355,
    ViewPresentations=356,
    ViewGlobalBenefits=357,
    ViewGlobalRetirementPlans=358,
    ViewGlobalHealthcarePerformance=359,
    ViewMultinationalPooling=360,
    ViewCompanyCars=361,
    ViewVendorsDashboard=362,
    ViewPensionReportingSchedule= 363,
    AttestationVendorInMyData= 364,
    AttestationClientInMyData= 365,
    ViewHelp= 366,
    ViewContactAlta= 367,
    Resources = 368,
    ViewClientGeneralMaintenance = 369,
    ViewClientGeneralMaintenanceInflation = 370,
    ViewClientGeneralMaintenanceSalaryIncrease = 371,
    ViewClientGeneralMaintenanceMedicalTrend = 372,

    ViewApexGeneralMaintenance = 373,
    ViewApexGeneralMaintenanceInflation = 374,
    ViewApexGeneralMaintenanceSalaryIncrease = 375,
    ViewApexGeneralMaintenanceMedicalTrend = 376
}