import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ResourceSectionDto } from '../../dto/resources/resource-section.dto';
@Injectable({providedIn: 'root'})
export class ResourceSectionService {

    constructor(private http: HttpClient) { }

    getList = (): Observable<Array<ResourceSectionDto>> => this.http.get<Array<ResourceSectionDto>>(`${environment.apiUrl}/api/resource-section/list`);    

    getByName = (sectionName: string) => this.http.get<ResourceSectionDto>(`${environment.apiUrl}/api/resource-section/${sectionName}`);
    
}