import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get("skip")) return next.handle(req);
    if (req.url.includes('s3.amazonaws.com')) return next.handle(req);

    const token = this.getToken();

    const newRequest = req.clone({
      headers: req.headers.set("Authorization", `Bearer ${token}`)      
    });

    return next.handle(newRequest);    
  }

  getToken() {
    let token = localStorage.getItem("access_token") ?? '';
    token = token ? token.replace(/["']/g, "") : "";
    return token;
  }
}