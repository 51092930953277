import { ActivatedRoute } from '@angular/router';
import { Router, RouterModule } from '@angular/router';
registerLocaleData(en);
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  LocationStrategy,
  HashLocationStrategy,
  registerLocaleData,
} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';

const APP_CONTAINERS = [DefaultLayoutComponent];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { LoginuserComponent } from './components/authorization/loginuser/loginuser.component';
import { ForgotPasswordComponent } from './components/authorization/forgotpassword/forgotpassword.component';
import { ForgotUsernameComponent } from './components/authorization/forgotusername/forgotusername.component';
import { ResetPasswordComponent } from './components/authorization/resetpassword/resetpassword.component';
import { ValidateAccountComponent } from './components/authorization/validateaccount/validateaccount.component';
// import { DefaultFilterComponent } from './filter/default-filter.component';

// import { DefaultFilterComponent } from './components/report/filter/default-filter.component';

// Import Alta  Modules
// The angular-jwt library implements the code needed for sending the access token along with each HTTP request but it needs some setup.
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { TranslateService } from './shared/service/translate.service';

import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgZorroAntdModule, NZ_ICONS, NZ_I18N, en_US } from 'ng-zorro-antd';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NoticeHomeModalComponent } from './notice-home-modal/notice-home-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'primeng/tooltip';
import en from '@angular/common/locales/en';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { CardsArticlesComponent } from './components/authorization/cards-articles/cards-articles.component';
import { SortPipe } from './shared/pipes/sort.pipe';
import { CorporateSelectorModule } from './components/corporateselector/corporateselector.module';
import { DefaultpageComponent } from './components/defaultpage/defaultpage.component';
import { NoaccessModule } from './components/noaccess/noaccess.module';
import { TokenInterceptor } from './shared/provider/token-interceptor';
import { AuthInterceptor } from './shared/provider/auth-intercepter';
// import { MyProjectsComponent } from './components/myProjects/my-projects/my-projects.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CorporateSelectorModule,
    NgZorroAntdModule,
    TooltipModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(options),
    NgxDocViewerModule,
    NoaccessModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: [environment.apiUrl],
        blacklistedRoutes: [`${environment.apiUrl}/api/authentication/login`],
      },
    }),
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginuserComponent,
    CardsArticlesComponent,
    ForgotPasswordComponent,
    NoticeHomeModalComponent,
    ForgotUsernameComponent,
    ResetPasswordComponent,
    ValidateAccountComponent,
    DefaultpageComponent,
    // MyProjectsComponent,
  ],
  providers: [
    TranslateService,
    SortPipe,    
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NZ_ICONS, useValue: icons },
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS,
      useFactory: function(router: Router, activatedRoute: ActivatedRoute) {
        return new AuthInterceptor(router, activatedRoute);
      },
      multi: true,
      deps: [Router, ActivatedRoute] 
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [NoticeHomeModalComponent],
})
export class AppModule { }

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}
