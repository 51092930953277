// import { NgModule } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { AltaBmsService } from "../../../shared/service/altabms.service";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { UtilityService } from "../../../shared/service/utility.service";
// import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-validateaccount",
  templateUrl: "./validateaccount.component.html",
  styleUrls: ["./validateaccount.component.css"],
  // providers: [ConfirmationService],
})
export class ValidateAccountComponent implements OnInit {
  loading = true;
  eml = "";
  codval = "";
  constructor(
    private formBuilder: FormBuilder,
    private altaBmsService: AltaBmsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private utilityService: UtilityService // private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((parameters) => {
      this.eml = parameters["params"].eml;
      this.codval = parameters["params"].stc;

      //todo:validate if the code and email come in the url
      this.validateAccount();
    });
  }
  // Login Method
  validateAccount() {
    this.loading = true;

    this.altaBmsService
      .get(`ValidateAccount?email=${this.eml}&validationcode=${this.codval}`)
      .subscribe(
        (result) => {
          if (JSON.stringify(result["Success"]) != "false") {
            const data = <any>result["Data"];

            this.toastr.success(
              `${"The account was validated, the system will redirect in a few seconds or click to go manually."}`,
              "Info"
            );
          } else {
            this.toastr.error(result["Message"], "Error");
          }

          this.loading = false;
        },
        (error) => {
          this.utilityService.getErrorMessages(this, error);

          this.loading = false;
        }
      );
  }
}
