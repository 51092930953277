// import { NgModule } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { AltaBmsService } from "../../../shared/service/altabms.service";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { UtilityService } from "../../../shared/service/utility.service";
import { NzAlertModule } from 'ng-zorro-antd/alert';

// import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-resetpassword",
  templateUrl: "./resetpassword.component.html",
  styleUrls: ["./resetpassword.component.css"],
  // providers: [ConfirmationService],
})
export class ResetPasswordComponent implements OnInit {
  loading = false;
  submitted = false;
  resetPasswordForm: FormGroup;
  userId: any;
  //public barLabel: string = "Password strength:"; 
  // ModalHeader = "";
  // ModalContent = "";
  // displayModalMessage = false;

  constructor(
    private formBuilder: FormBuilder,
    private altaBmsService: AltaBmsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private utilityService: UtilityService // private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      emx: "",
      vlc: "",
      newpassword: ["", [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@.#$!%*?&]{8,25}$/)]],
      confirmpassword: ["", [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@.#$!%*?&]{8,25}$/)]],
    });

    this.route.paramMap.subscribe((parameters) => {
      this.record.emx.setValue(parameters["params"].eml);
      this.record.vlc.setValue(parameters["params"].stc);
      this.userId = parameters["params"].userId;
    });
  }

  get record() {
    return this.resetPasswordForm.controls;
  }

  // Login Method
  resetPasswordSubmit() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    this.altaBmsService
      .get(
        `resetingpassword?email=${this.record.emx.value}&newpassword=${this.record.newpassword.value}&validationcode=${this.record.vlc.value}&userId=${this.userId}`
      )
      .subscribe(
        (result) => {
          if (JSON.stringify(result["Success"]) != "false") {
            const data = <any>result["Data"];

            this.toastr.success(
              `${"The password was changed successfully."}`,
              "Info"
            );

            // this.ModalHeader = "Information";
            // this.ModalContent = `${"The password was changed successfully."}`;
          } else {
            this.toastr.error(result["Message"], "Error");
            // this.ModalHeader = "Error";
            // this.ModalContent = `${result["Message"]} <br>`;
          }

          this.loading = false;
        },
        (error) => {
          this.utilityService.getErrorMessages(this, error);
          // const errorMessage = this.utilityService.getErrorMessageText(error);
          // this.ModalHeader = "Error";
          // this.ModalContent = errorMessage;

          this.loading = false;
        }
      );
  }
}
