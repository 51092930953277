import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Event,
} from "@angular/router";
import { Subject } from "rxjs";
import { Component, Renderer2 } from "@angular/core";
import { navBarItems } from "../../_nav";
import { User } from "../../shared/model/user.model";
import { TranslateService } from "../../shared/service/translate.service";
import { AltaBmsService } from "../../shared/service/altabms.service";
import { LocalstorageService } from "../../shared/service/localstorage.service";
import { UtilityService } from '../../shared/service/utility.service';
import { isNullOrUndefined } from "util";
import { LayoutService } from './layout.service'
import { takeUntil } from "rxjs/operators";

declare var zoho:any;
@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.css"],
})
export class DefaultLayoutComponent {

  public sidebarMinimized = false;
  public navItems = [];
  public translateApp = {};
  public currentUser;
  public cUser: User;
  public linkActive: true;
  loading: boolean = false;
  showModalNotifier: Subject<boolean> = new Subject<boolean>();
  public corporateLogo: string = "assets/img/brand/logo-example.jpg";
  containerHeight$: string
  private unsubscribe = new Subject<void>();


  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  constructor(
    private navbar: navBarItems,
    private router: Router,
    private altaBmsService: AltaBmsService,
    private translateService: TranslateService,
    private render: Renderer2,
    private localStorageService: LocalstorageService,
    private utilityService: UtilityService,
    private layoutService: LayoutService
  ) {

    layoutService.containerHeight$.pipe(takeUntil(this.unsubscribe)).
    subscribe(
      res => {
        this.containerHeight$ = res
      });

    this.currentUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : "";

    this.cUser = this.currentUser as User;    

    if (!isNullOrUndefined(this.currentUser)) {
      console.log('user logged in');
      
    }

   // this.refreshpermissions()

    if (this.cUser.Profile.LogoURL != null && this.cUser.Profile.LogoURL != "")
      this.corporateLogo = this.cUser.Profile.LogoURL ?? "";

    let scope = this;      
    this.refreshSessionProfile().then(() => {
      if(this.cUser.Profile['Actions'] !== null || this.cUser.Profile.CorporateId !== null || this.cUser.Profile['IsSuperAdmin'] == true || this.cUser.Profile['IsOperational'] == true) {
        this.translateService.getTranslateData(function (data: any) {

          scope.translateApp = data;
          
          scope.navbar.getMenu(data);
          scope.navbar.navItems.subscribe({
            next: (data) => {            
              scope.navItems = data;
            }
          })
        });
      }
    });

    this.router.events.subscribe((event: Event) => {
      
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });



    /**
     * Zoho chat
     */
    // zoho.onChatReady(($zoho) => {
    //   zoho.floatButton.visible(false);
    // })
    
  }

  ngAfterViewInit() {
    const classArr: any = document.querySelectorAll(".navbar-brand-full");
    classArr.forEach((element) => {
      this.render.listen(element, "click", (target) => {
        this.clickoncorp();
      });
    });
  }

  clickoncorp() {
    this.showModalNotifier.next(true);
  }

  onLogout() {
    let url = this.router.url;
    const lastScreen = url.indexOf("/") === 0 ? url.substring(1) : url;
    this.altaBmsService.logout(lastScreen).subscribe(
      (result) => {
        return true;
      },
      (error) => {}
    );
  }

  refreshpermissions(){
    const userString = this.localStorageService.getUserFromStorage();
    let currentUser;
    
    if (!isNullOrUndefined(userString)) {
      currentUser = JSON.parse(userString);
    }

    this.altaBmsService
      .get(
        `refreshpermissions?userId=${currentUser["Profile"].UserId}&corporateId=${currentUser["Profile"].CorporateId}`
      )
      .subscribe(
        (result) => {
          const data = <any>result["Data"];

          if (data) {
            currentUser["Profile"] = {
              ...currentUser["Profile"],
              Actions: data
            }

            this.localStorageService.setLocalStorageItem(
              "currentUser",
              currentUser
            );

            this.utilityService.reloadPermissions().then((res) => {
              document.location.reload();
            });
          }
        },
        (error) => {
          this.loading= false;
        }
      );
  }

  refreshSessionProfile() {
    const userString = this.localStorageService.getUserFromStorage();
    let currentUser;

    return new Promise((resolve) => {
      if (!isNullOrUndefined(userString)) {
        currentUser = JSON.parse(userString);

        this.altaBmsService.get(`refreshpermissions?userId=${currentUser["Profile"].UserId}&corporateId=${currentUser["Profile"].CorporateId}`).subscribe((result) => {
          const actions = <any>result["Data"];

          this.altaBmsService.get(`user/${currentUser["Profile"].UserId}/profile`)
            .subscribe((result) => {
              const data = <any>result["Data"];
              if (data) {
                currentUser["Profile"] ={
                  ...currentUser["Profile"],
                  ...data["Profile"],
                  CorporateName: currentUser["Profile"].CorporateName,
                  LogoURL: currentUser["Profile"].LogoURL,
                  Actions: actions
                };

                this.localStorageService.setLocalStorageItem(
                  "currentUser",
                  currentUser
                );

                resolve(true);
              }
            });
        });
      } else {
        resolve(true);
      }
    });
  }

  ngDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // showChat() {
  //   zoho.chat.show();
  // }
}

