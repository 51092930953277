import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
        
    if (err.status === 401) { // || err.status === 403

        if(this.router.url !== '/login') {

          this.router.navigateByUrl(`/login`);
          alert("Your session has expired");
          return of(err.message);
        }        
    }

    return throwError(err);
}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get("skip")) return next.handle(req);
    if (req.url.includes('s3.amazonaws.com')) return next.handle(req);

    const token = this.getToken();
    const headers = req.headers.set('Authorization', `Bearer ${token}`);
    const authReq = req.clone({ headers: headers });

    return next.handle(authReq).pipe(catchError(x => this.handleAuthError(x)));
  }

  getToken() {
    let token = localStorage.getItem("access_token") ?? '';
    token = token ? token.replace(/["']/g, "") : "";
    return token;
  }
}
