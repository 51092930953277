import { Component } from "@angular/core";
import { Location } from '@angular/common';
@Component({
  templateUrl: "404.component.html",
  styleUrls: ["./404.css"],
})
export class P404Component {

  constructor(private location: Location) {}

  goBack() {
    this.location.back();
  }
}
