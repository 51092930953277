import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Article, ArticleFiles } from '../../shared/model/Articles.model';
import { AltaBmsService } from '../../shared/service/altabms.service';

@Injectable({
  providedIn: 'root',
})
export class LoginContentEditorService {
  constructor(private service: AltaBmsService) {}
  
  // Article List
  getArticleList(
    start: number = 1,
    limit: number = 100,
    dir: string = 'ASC',
    search?: string
  ) {
    return this.service
      .get(`article?start=${start}&limit=${limit}&dir=${dir}&search=${search}`)
      .pipe(
        map((result) => {
          return result as Article;
        })
      );
  }

  // Article By Id
  getArticleById(
    articleId: number
  ) {
    return this.service
      .get(`article/${articleId}`)
      .pipe(
        map((result) => {
          return result as Article;
        })
      );
  }

  // Create Articles
  createArticle(article: Article) {
    return this.service
      .postData(`article`, article)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Update Articles
  updateArticle(article: Article) {
    return this.service
      .putData(`article/update`, article)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }


  // Delete Article
  deleteArticle(articleId: number) {
    return this.service
      .putData(`article/${articleId}/delete`, {})
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

// ArticleFiles List
getArticleFilesById(articleId: number){
  return this.service
  .get(`article-files/${articleId}`)
  .pipe(map((result) => {
    return result;
  }));
}


// Create Article Files
createArticleFile(articleFiles: ArticleFiles) {
  return this.service
    .postData(`article-files`, articleFiles)
    .pipe(
      map((result) => {
        return result;
      })
    );
}

uploadArticleFiles(
  articleId: number,
  formData: FormData
) {
  return this.service
    .uploadFileGlobal(`article-files/${articleId}/upload`, formData)
    .pipe(
      map((result) => {
        return result;
      })
    );
}

deleteArticleFiles( articleFilesId: number) {
  return this.service
  .putData(`article-files/${articleFilesId}/delete`,{})
  .pipe(
      map(result => {
      return result;
    })
  );
}

}
