import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgZorroAntdModule } from "ng-zorro-antd";

import { CorporateSelectorComponent } from "../corporateselector/corporateselector.component";

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    NgZorroAntdModule
  ],
  exports: [CorporateSelectorComponent],
  declarations: [CorporateSelectorComponent],
})
export class CorporateSelectorModule {}
