import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { LocalstorageService } from "../../shared/service/localstorage.service";
import { Title } from "@angular/platform-browser";
import { ModalDirective } from "ngx-bootstrap/modal";
import { isNullOrUndefined } from "util";
import { AltaBmsService } from "../../shared/service/altabms.service";
import { UtilityService } from '../../shared/service/utility.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-corporateselector",
  templateUrl: "./corporateselector.component.html",
  styleUrls: ["./corporateselector.component.css"],
})
export class CorporateSelectorComponent implements OnInit {
  title = "HUB - Select Corporate";
  corporates : any;
  loading = false;

  @Input() showModal: Subject<boolean>;
  @ViewChild("corporateSelectorModal")
  public corporateSelectorModal: ModalDirective;

  constructor(
    private localStorageService: LocalstorageService,
    private titleService: Title,
    private altaBmsService: AltaBmsService,
    private utilityService : UtilityService,
    private router: Router
  ) {
    //this.loadCorporates();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.showModal.subscribe((data) => {
      if (data) this.loadCorporates(true);
      else this.corporateSelectorModal.hide();
    });
    const me = this;
    setTimeout(function () {
      me.loadCorporates(false);
    }, 1000);
  }

  loadCorporates(force) {
    const userString = this.localStorageService.getUserFromStorage();

    if (!isNullOrUndefined(userString)) {
      let currentUser = JSON.parse(userString);
      currentUser = currentUser.Profile;
      this.corporates = currentUser["Corporates"];
      
      if(this.corporates && this.corporates !== '')
        this.corporates = JSON.parse(this.corporates);

      if (
        !currentUser.IsSuperAdmin &&
        this.corporates != null &&
        this.corporates.length > 0
      ) {
        if (
          force ||
          (currentUser.CorporateId === null
            ? 0
            : currentUser.CorporateId) === 0
        ) 
        {
          this.corporateSelectorModal.show();
        }
      }
    }
  }

  selectCorporate(corporate,bydefault) {
    this.loading= true;
    const userString = this.localStorageService.getUserFromStorage();
    let currentUser;
    if (!isNullOrUndefined(userString)) {
      currentUser = JSON.parse(userString);
    }

    this.altaBmsService
      .get(
        `setcorporatetouse?userId=${currentUser["Profile"].UserId}&corporateId=${corporate.CorporateId}&bydefault=${bydefault}`
      )
      .subscribe(
        (result) => {
          const data = <any>result["Data"];

          if (data) {
            currentUser["Profile"] ={
              ...currentUser["Profile"],
              Actions: data.Actions
            }

            let currentcorps = data.Corporates;
            if(currentcorps && currentcorps!= '')
            {
                currentcorps = JSON.parse(currentcorps);

                const corpselected = currentcorps.find(c=> c.CorporateId === data.CorporateId);
                currentUser["Profile"] = {
                  ...currentUser["Profile"],
                  CorporateId: corpselected.CorporateId,
                  CorporateName: corpselected.Corporate,
                  LogoURL: corpselected.LogoURL,
                  CorporateCatalogValues: data.CorporateCatalogValues
                };
            }

            this.localStorageService.setLocalStorageItem(
              "currentUser",
              currentUser
            );

            this.utilityService.reloadPermissions().then((res) => {
              this.redirectToScreen();
              setTimeout(function(){
                location.reload();
              },2000)
            });
          }
        },
        (error) => {
          this.loading= false;
        }
      );
  }

  redirectToScreen() {
      let user = this.localStorageService.getUserFromStorage();

      if(user && user!="")
        user = JSON.parse(user);

      let redirectToRoute = "home";

      if (user["Profile"]["FirstPage"] === "toPredefinedScreen") {
        const homeType = user["Profile"]["HomePageType"];

        if (homeType === "Last Screen") {
          redirectToRoute = user["Profile"]["LastScreenUsed"] || "home";
        } else if (homeType === "Custom") {
          redirectToRoute = user["Profile"]["PredefinedScreen"] || "home";
        } else {
          redirectToRoute = "home";
        }
      } else if (user["Profile"]["FirstPage"] === "toLastScreen") {
        redirectToRoute = user["Profile"]["LastScreenUsed"] || "home";
      }

      let decodedRoute = decodeURIComponent(redirectToRoute);

      if(decodedRoute=="home")//verify if have access to home page, if not redirect to default page
      {
        if(!this.utilityService.havePermission(258))
          decodedRoute = decodeURIComponent("default");
      }
      
      this.router.navigateByUrl(`/${decodedRoute}`);
  }
}
