import { Component, OnInit } from "@angular/core";
import { AltaBmsService } from "../../../shared/service/altabms.service";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilityService } from "../../../shared/service/utility.service";

@Component({
  selector: "app-forgotpassword",
  templateUrl: "./forgotpassword.component.html",
  styleUrls: ["./forgotpassword.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  loading = false;
  submitted = false;
  forgotPasswordForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private altaBmsService: AltaBmsService,
    private toastr: ToastrService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ["", Validators.required],
    });
  }

  get record() {
    return this.forgotPasswordForm.controls;
  }

  // Login Method
  forgotPasswordSubmit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    this.altaBmsService
      .get(`requestresetpassword?email=${this.record.email.value}`)
      .subscribe(
        (result) => {
          const data = <any>result["Data"];

          if (data) {
            this.toastr.success(
              "An email has been sent it you, please check your inbox or spam folder."
            );
          }

          this.loading = false;
        },
        (error) => {
          this.utilityService.getErrorMessages(this, error);
          this.loading = false;
        }
      );
  }
}
