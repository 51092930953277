import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AltaBmsService } from "../../../shared/service/altabms.service";
import { UtilityService } from "../../../shared/service/utility.service";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  BsModalService,
  BsModalRef,
  ModalDirective,
} from 'ngx-bootstrap/modal';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-loginuser',
  templateUrl: './loginuser.component.html',
  styleUrls: ['./loginuser.component.css'],
})
export class LoginuserComponent implements OnInit {
  @ViewChild("loginModal") public loginModal: ModalDirective;
  @ViewChild("termsModal") public termsModal: ModalDirective;

  hiddenPdfButton: boolean = false;
  contentNewsLogin: string = '';
  titleNewsLogin: string = '';
  linkPdf: string = '';
  loginForm: FormGroup;
  loading = false;
  loadingtc = false;
  submitted = false;
  returnUrl: string;
  modalRef: BsModalRef;
  bodyNewsSplited: string[];
  title = "HUB - Login";
  currentUser = {};
  acceptTermsCheck = false;
  mfaChallenge = false;
  OTP = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private altaBmsService: AltaBmsService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private titleService: Title,
    private utilityService: UtilityService,
    private httpClient: HttpClient,
  ) {}

  ngOnInit(): void {
    this.utilityService.clearCache();
    this.titleService.setTitle(this.title);
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
      mfaCode: [""],
      loginScreenRB: ["home", Validators.required],
      termsLogin: [false, Validators.required],
    });
    
  }

  // for accessing to form fields
  get record() {
    return this.loginForm.controls;
  }

  selectTakeMeTo(e) {
    this.loginForm.get('loginScreenRB').setValue(e.target.value);
  }

  downloadTerms() {
    this.altaBmsService.postData('authentication/generate-terms-presigned-url', null)
    .subscribe((result) => {
      console.log(result);

      const httpOptions = {
        responseType: 'blob' as 'json'
      };

      this.httpClient.get(result, httpOptions).subscribe(file => {
        this.downLoadFile(file, 'application/pdf')
      });      
    });
  }

  getFile(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    
    return this.httpClient.get(url, httpOptions).toPromise()
  }

  /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert( 'Please disable your Pop-up blocker and try again.');
    }
}

  // Login Method
  onFormSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    
    if(this.mfaChallenge) {
      if(this.record.mfaCode.value == '') {
        this.toastr.error("The security code is required", "Error");
        this.loading = false;

        return;
      } else {
        if(this.record.mfaCode.value != this.OTP) {
          this.toastr.error("The security code is invalid", "Error");
          this.loading = false;

          return;
        } else {
          if (this.currentUser["Profile"]["ShowTermsAndConditions"]) {
            this.termsModal.show();
          } else {
            this.OTP = '';
            this.utilityService.clearCache();
            this.utilityService.setUserData(this.currentUser);
            this.utilityService.reloadPermissions().then((result) => {
              this.redirectToScreen();
            });
          }

          return;
        }
      }
    } 

    this.altaBmsService
      .login(this.record.username.value, this.record.password.value)
      .subscribe(
        (user) => {
          // console.log('user', user);

          if(
            user['Data']['Profile']['CorporateId'] != null &&
            user['Data']['Profile']['Corporates'].split('CorporateId').length - 1 > 1 && 
            user['Data']['Profile']['Corporates'].split('IsDefault":true').length -1 == 0
          ) {
            user['Data']['Profile']['CorporateId'] = null;
          }
          user["Data"]["Profile"]["FirstPage"] = this.record.loginScreenRB.value;
          
          this.currentUser = {
            Token: user["Data"]["Token"],
            Profile: user["Data"]["Profile"],
          };

          if(user["Data"]["OTP"] != '') {
            this.mfaChallenge = true;
            this.OTP = user["Data"]["OTP"];
            this.loading = false;
            return;
          }

          if (user["Success"] === true) {
            if (this.currentUser["Profile"]["ShowTermsAndConditions"]) {
              this.termsModal.show();
            } else {
              this.utilityService.clearCache();
              this.utilityService.setUserData(this.currentUser);
              this.utilityService.reloadPermissions().then((result) => {
                this.redirectToScreen();
              });
            }
          } else {
            this.toastr.error(user["Message"], "Error");
          }

          this.loading = false;
        },
        (error) => {
          // console.log(error);
          
          this.utilityService.getErrorMessages(this, error);
          this.loading = false;
        }
      );
  }

  acceptTerms() {
    this.loadingtc = true;
    if (!this.acceptTermsCheck) {
      this.toastr.error(
        "You have to accept the terms and conditions.",
        "Error"
      );
      this.loadingtc = false;
      return;
    }

    this.altaBmsService
      .get(
        `ShowTermsAndConditions?userId=${
          this.currentUser["Profile"]["UserId"]
        }&show=${!this.acceptTermsCheck}`
      )
      .subscribe(
        (result) => {
          if (result.Success) {
            this.utilityService.setUserData(this.currentUser);
            this.utilityService.reloadPermissions().then((res) => {
              this.redirectToScreen();
            });
          }

          this.loadingtc = false;
        },
        (error) => {
          this.toastr.error(error.error.message, "Error");
          this.loadingtc = false;
        }
      );
  }

  redirectToScreen() {
    const me = this;
    //setTimeout(function () {
      const user = me.currentUser;
      let redirectToRoute = "home";
      if (me.record.loginScreenRB.value === "toPredefinedScreen") {
        const homeType = user["Profile"]["HomePageType"];

        if (homeType === "Last Screen") {
          redirectToRoute = user["Profile"]["LastScreenUsed"] || "home";
        } else if (homeType === "Custom") {
          redirectToRoute = user["Profile"]["PredefinedScreen"] || "home";
        } else {
          redirectToRoute = "home";
        }
      } else if (me.record.loginScreenRB.value === "toLastScreen") {
        redirectToRoute = user["Profile"]["LastScreenUsed"] || "home";
      }

      let decodedRoute = decodeURIComponent(redirectToRoute);

      if(decodedRoute=="home")//verify if have access to home page, if not redirect to default page
      {
        let corporates = this.currentUser['Profile']['Corporates'];
        if(!this.utilityService.havePermission(258)) {
          decodedRoute = decodeURIComponent("default");
        } else if(corporates != null) {
          if(corporates.split('CorporateId').length - 1 > 1 && corporates.split('IsDefault":true').length -1 == 0) {
            decodedRoute = decodeURIComponent("default");
          }
        }
      }

      me.router.navigateByUrl(`/${decodedRoute}`);
    //}, 1000);
  }

  // Modal Notice
  openLoginModal(nameOfCard: string = '', numberOfNews: number = 0) {
    if (nameOfCard === 'regulatoryDevelopments') {
      if (numberOfNews === 1) {
        this.titleNewsLogin = 'Netherlands | Major Pension Reform';
        this.contentNewsLogin = `After reaching an agreement with its social partners following more than a year of deliberations, the Dutch parliament announced a major reform of its pension system whereby its traditional defined benefit (DB) arrangements will be replaced by a defined contribution (DC) system favoring individual savings accounts using a life-cycle investment framework. The new legislation is expected to come into effect on January 1, 2022 with a 4-year period for full implementation.`;
        this.bodyNewsSplited = this.contentNewsLogin.split('.');
        this.hiddenPdfButton = false;
        this.linkPdf = '../../../../assets/docs/Netherlands-Pension Reform.pdf';
      } else if (numberOfNews === 2) {
        this.titleNewsLogin = 'Switzerland | Gender Equality Audit Requirement';
        this.contentNewsLogin = `The Federal Act on Gender Equality was amended effective as of July 1, 2020 to require companies with more than 100 employees to conduct a periodic analysis on equal pay by gender.
        The first audit is due by June 30, 2021 and must be submitted to a qualified independent auditor for review. The company must share the results with its workforce, although the amendment does not address any remedial measures where unsubstantiated gender pay inequalities have been observed.
        The amendment contains a sunset clause whereby the provisions expire on July 1, 2032.
        `;
        this.bodyNewsSplited = this.contentNewsLogin.split('.');
        this.hiddenPdfButton = true;
      } else if (numberOfNews === 3) {
        this.titleNewsLogin = 'India | Mandatory Health Insurance';
        this.contentNewsLogin = `As one of the Indian government requirements to resume business after the Covid-19 lockdown, companies have to provide medical insurance to all their employees. Although a common benefit to provide, medical insurance was not mandatory in the past.
        Minimum coverage has not been prescribed but the insurance regulator, through a circular issued on April 16, 2020, advised insurers to offer comprehensive coverage at an affordable cost. Employees covered under the Employees State Insurance Scheme (ESI) are exempt from this requirement.
        Companies should review their health insurance programs to determine whether any employees have a gap in compliant coverage.
        `;
        this.bodyNewsSplited = this.contentNewsLogin.split('.');
        this.hiddenPdfButton = true;
      }
    } else if (nameOfCard === 'knowledgeCenter') {
      if (numberOfNews === 1) {
        this.titleNewsLogin =
          'HUB International has been actively advising the multinational community';
        this.contentNewsLogin =
          'HUB International has been actively advising the multinational community in terms of actions to be undertaken with respect to non-US local employees as a response to the Covid-19 crisis. Visit our website at www,altaactuaries,com for a brief article summarizing our suggestions.';
        this.bodyNewsSplited = this.contentNewsLogin.split('.');
        this.hiddenPdfButton = false;
        this.linkPdf = '../../../../assets/docs/Gaitan-Jan-20.pdf';
      } else if (numberOfNews === 2) {
        this.titleNewsLogin =
          'HUB International has conducted extensive research on special issues';
        this.contentNewsLogin = `HUB International has conducted extensive research on special issues in managing global benefits at small companies and locations.  Click below to download a brief article written by Wil Gaitan, a Principal and Global Consulting Actuary of Alta, published in 
        the January 2020 issue of the Benefits Magazine of the International Foundation of Employee Benefit Plans summarizing our findings. 
        `;
        this.bodyNewsSplited = this.contentNewsLogin.split('.');
        this.hiddenPdfButton = true;
      }
    } else if (nameOfCard === 'peopleMovement') {
      if (numberOfNews === 1) {
        this.titleNewsLogin =
          'HUB International is pleased to announce the incorporation of the Principals';
        this.contentNewsLogin = `HUB International is pleased to announce the incorporation of the following Principals to our practice in Europe – Renata De Leers, based in Paris and Wilfried Van Messem based in Brussels.
        Additionally, Julio Galhardo has joined us as Principal based in Sao Paulo, Brazil. All of our new Principals are experienced global consulting actuaries.`;
        this.bodyNewsSplited = this.contentNewsLogin.split('.');
        this.hiddenPdfButton = true;
      } else if (numberOfNews === 2) {
        this.titleNewsLogin =
          'Our HUB International Center of Excellence also welcomes Cairo Leiva Cajina';
        this.contentNewsLogin = `Our HUB International Center of Excellence also welcomes Cairo Leiva Cajina, who has joined our Center of Excellence.
        Cairo is a Political Science and International Relations  graduate.
        `;
        this.bodyNewsSplited = this.contentNewsLogin.split('.');
        this.hiddenPdfButton = true;
      } else if (numberOfNews === 3) {
        this.titleNewsLogin = 'Tim Wolf has joined the Liberty Financial Group';
        this.contentNewsLogin =
          'Tim Wolf has joined the Liberty Financial Group which now a representative carrier of the Swiss Life Network in the USA.';
        this.bodyNewsSplited = this.contentNewsLogin.split('.');
        this.hiddenPdfButton = true;
      }
    } else if (nameOfCard === 'events') {
      if (numberOfNews === 1) {
        this.titleNewsLogin =
          'HUB International has been certified as a Minority Owned Company';
        this.contentNewsLogin =
          'HUB International has been certified as a Minority Owned Company by the National Minority Supplier Development Council.';
        this.bodyNewsSplited = this.contentNewsLogin.split('.');
        this.hiddenPdfButton = true;
      } else if (numberOfNews === 2) {
        this.titleNewsLogin =
          'HUB International will be sponsoring a booth at the upcoming Global Benefits';
        this.contentNewsLogin =
          'HUB International will be sponsoring a booth at the upcoming Global Benefits CONNECT Summit to be held in Boston in June of 2021.';
        this.bodyNewsSplited = this.contentNewsLogin.split('.');
        this.hiddenPdfButton = true;
      }
    }
    this.loginModal.show();
  }

  hideLoginModal() {
    this.loginModal.hide();
  }
}
