import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-notice-home-modal",
  templateUrl: "./notice-home-modal.component.html",
  styleUrls: ["./notice-home-modal.component.css"],
})
export class NoticeHomeModalComponent implements OnInit {
  title: string;
  content: string;

  constructor(public modalRef: BsModalRef) {}

  ngOnInit(): void {}
}
