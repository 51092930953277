import { Injectable } from "@angular/core";
import { AltaBmsService } from "./altabms.service";
import { Language } from "../model/languageapp";

@Injectable({
  providedIn: "root",
})

export class TranslateService {
  constructor(private altaBmsService: AltaBmsService) {  }

  getTranslateData(callback) {
    if (Object.keys(Language).length > 0) {
      callback(Language);
      return;
    }

    this.altaBmsService.getTranslateEntity("GeneralApp").subscribe(
      (result) => {
        const data = result["Data"].ScreenValues;
        Object.keys(data).map((ele, i) => {
          Language[ele] = data[ele];
        });
        callback(Language);
      },
      (error) => {
        callback({});
      }
    );
  }

  getTranslateEntity(screenEntity: string, callback) {
    if (Language[screenEntity] != undefined && Object.keys(Language[screenEntity]).length > 0) {
      callback(Language[screenEntity]);
      return;
    }

    this.altaBmsService.getTranslateEntity(screenEntity).subscribe(
      (result) => {
        const data = result["Data"]?.ScreenValues;
        if(data == undefined){
          callback({});
          return;
        }

        const entityLanguage = {};
        Object.keys(data).map((element, i) => {
          entityLanguage[element] = data[element];
        });
        Language[screenEntity] = entityLanguage;
        callback(Language[screenEntity] || {});
      },
      (error) => {
        callback({});
      }
    );
  }
}