import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

//Authentication Service
import { UtilityService } from "../service/utility.service";
// import { ActionEnum } from "../../shared/actionenum";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private utilityService: UtilityService // private utilityService: UtilityService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLogged = this.utilityService.loggedIn;

    if (isLogged) {
      // authorised so return true
      return true;
    }

    // if (!this.utilityService.validateAccessToAction())
    //   alert("redireccionar a una pagina de no autorizado");

    // not logged in so redirect to login page
    this.utilityService.clearCache();
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } }); //, { queryParams: { returnUrl: state.url } }
    return false;
  }
}
