import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-defaultpage',
  templateUrl: './defaultpage.component.html',
  styleUrls: ['./defaultpage.component.css']
})
export class DefaultpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
